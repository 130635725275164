import $ from 'jquery'
import Cookie from 'js-cookie'

import 'vendor/legacy/jquery-autocomplete/jquery.auto-complete.js'
import 'vendor/legacy/jquery-autocomplete/jquery.auto-complete.css'

(function() {
    // ----------------------------------------------------

    $(window).ready(() => {
        // toggle active class for button groups
        $('div.btn-group').each((_, group) => {
            let $buttons = $(group).find('button[data-toggle]').on('click', evt => {
                $buttons.removeClass('active')
                $(evt.target).addClass('active')
            })
        })
    })

})();



// ----- legacy ------



(function() {
    $('.top-navigation li.dropdownit a').on('click', function (event) {
        $('.top-navigation li.dropdownit').removeClass('open');
        $(this).parent().toggleClass("open");
    });

    $('body').on('click', function (e) {
        if (!$('.top-navigation li.dropdownit').is(e.target) && $('.top-navigation li.dropdownit').has(e.target).length === 0 && $('.open').has(e.target).length === 0) {
            $('.top-navigation li.dropdownit').removeClass('open');
        }
    });

    $(document).ready(function () {
        $('#collapse').on('click', function () {
            $('#collapsible').slideToggle(100);
        });
    });

    var suggest = $('#search').data('suggest');
    var xhr;

    $('input#search').autoComplete({
        source: function (term, response) {
            try {
                xhr.abort();
            } catch (e) {
            }
            //xhr = $.getJSON('/search/suggest', { q: term }, function(data){ response(data); });
            xhr = $.get(suggest, {q: term}, function (data) {
                response(data);
            });
        },
        minChars: 2,
        renderItem: function (item, search) {
            // escape special characters
            search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
            return '<div class="autocomplete-suggestion" data-link="' + item['link'] + '" data-val="' + item['name'] + '">' + item['name'].replace(re, "<b>$1</b>") + '</div>';
        },
        onSelect: function (e) {
            search(e);
        }
    });

    $('input#search').bind("enterKey", function (e) {
        search($(this).val());
    });

    $('input#search').keypress(function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            $(this).trigger("enterKey");
        }
    });

    $('button[name="search"]').on('click', function () {
        var q = $('input#search').val();
        if (q.length > 1) {
            search(q);
        }
    });

    function search(q) {
        var link = $('div.autocomplete-suggestion.selected').data('link');

        if (q.length >= 2) {
            if (typeof link != 'undefined') {
                window.location.href = link;
            } else {
                var url = $('#search').data('search');
                window.location.href = url + '?q=' + escape(q);
            }
        }
    }

})();
