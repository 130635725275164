import $ from 'jquery'

let resendVerifyTarget = document.querySelector('#resend-verification');

if (resendVerifyTarget) {
    resendVerifyTarget.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector('div.top-verify').classList.add('hidden');
        document.querySelector('div.top-verify-resent').classList.remove('hidden');

        $.post(resendVerifyTarget.getAttribute('data-action'), {}, () => {
            // do nothing
        });
    });
}

/**
 * load commonly used jquery functions
 */
define(['jquery'], function($, bootbox) {
    var obj = $({});
    // jquery tiny sub/pub
    $.each({on: "sub", one: "subone", off: "unsub", trigger: "pub"}, function(k,v) {
        $[v] = function(){ obj[k].apply(obj, arguments) };
    });

    // Production steps of ECMA-262, Edition 5, 15.4.4.18
    // Reference: http://es5.github.io/#x15.4.4.18
    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function (callback, thisArg) {
            var T, k;

            if (this == null) {
                throw new TypeError(' this is null or not defined');
            }

            var O = Object(this);
            var len = O.length >>> 0;

            if (typeof callback !== "function") {
                throw new TypeError(callback + ' is not a function');
            }

            if (arguments.length > 1) {
                T = thisArg;
            }

            k = 0;

            while (k < len) {
                var kValue;
                if (k in O) {
                    kValue = O[k];
                    callback.call(T, kValue, k, O);
                }
                k++;
            }
        };
    }

    $.fn.findAndSelf = function(selector) {
        return this.find(selector).add(this.filter(selector));
    }

    $.fn.replaceWithPush = function(content) {
        var $content = $(content);
        this.replaceWith($content);
        return $content;
    }

    $.findFirst = function(items, callback) {
		var i = 0, length = items.length;
		for ( ; i < length; i++ ) {
			if (callback(items[i], i)) {
                return items[i];
            }
		}

		return null;
	}

    // jquery onif (used to control button events)
    $.fn.onif = function() {
        // save original handler
        var handler = arguments[arguments.length - 1];
        // create handler proxy
        var proxy = function() {
            if (!$(this).data('--internal-disabled')) handler.apply(this, arguments);
            return false;
        }
        arguments[arguments.length - 1] = proxy;
        // forward event handler with proxy
        return obj.on.apply(this, arguments);
    }

    // flag object as disabled
    $.fn.disabled = function(className) {
        $(this).data('--internal-disabled', true);
        $(this).addClass((className) ? className : 'disabled');
        return this;
    };

    // flag object as enabled
    $.fn.enabled = function(className) {
        $(this).data('--internal-disabled', false);
        $(this).removeClass((className) ? className : 'disabled');
        return this;
    };

    // check if object is enabled
    $.fn.isEnabled = function() { return !Boolean($(this).data('--internal-disabled')); }

    // check if object is enabled
    $.fn.isDisabled = function() { return Boolean($(this).data('--internal-disabled')); }

    /**
     * create notification
     *
     * @param string message
     * @param array options (type [success], delay [3000], before [false])
     * @returns object
     */
    $.fn.createNotify = function(message, options) {
        $.each(this, function() {
            if (!options) options = {type: 'success', delay: 3000, before: false};
            // ----------------------------------------------
            if (!options.type) options.type = 'success';
            if (!options.delay) options.delay = 3000;
            if (!options.before) options.before = false;

            var $object = $(['<div class="notify alert alert-' + options.type + '">', '<button type="button" class="close" data-dismiss="alert">×</button>', message, '</div>'].join(''));
            if (options.before) {
                $(this).prev('div.notify').remove();
                $(this).before($object);
            } else {
                $(this).find('div.notify').remove();
                $(this).prepend($object);
            }

            if (options.delay > 0) $object.delay(options.delay).fadeOut(800, function() {
                    $(this).remove();
                });
            $object.find('button.close').one('click', function() {
                $object.remove();
            });
        });

        return $(this);
    }

    /**
     * create label (inline)
     *
     * @param string message
     * @param array options (type [success], delay [undefined, optional])
     * @returns object
     */
    $.fn.createLabel = function(message, options) {
        $.each(this, function() {
            if (!options) options = {type: 'success', delay: null, inputs: null};
            // -------------------------------------------
            if (!options.type) options.type = 'success';
            if (!options.delay) options.delay = null;
            if (!options.inputs) options.inputs = null;

            var $container = $(this).removeLabel();         // remove old label
            $container.data('--label-options', options);

            options.object = $(['<span class="label label-', options.type, '" style="margin:0 10px">', message, '</span>'].join(''));
            if (options.before) $container.before(options.object);
            else $container.after(options.object);

            if (options.delay > 0) {
                options.object.delay(options.delay).fadeOut(800, function() {
                    $container.removeLabel();
                });
                options.object.one('click', function() {
                    $container.removeLabel();
                });
            }

            if (options.inputs) {
                if (!(options.inputs instanceof jQuery)) {
                    $.each(options.inputs, function() {
                        $(this).addClass('input-' + options.type);
                    });
                } else {
                    options.inputs.addClass('input-' + options.type);
                }
            }
        });

        return $(this);
    }

    /**
     * remove label
     *
     * @returns object
     */
    $.fn.removeLabel = function() {
        $.each(this, function() {
            if ($(this).data('--label-options')) {
                var options = $(this).data('--label-options');
                if (options.object) options.object.remove();
                if (options.inputs) {
                    if (!(options.inputs instanceof jQuery)) {
                        $.each(options.inputs, function() {
                            $(this).removeClass('input-' + options.type);
                        });
                    } else {
                        options.inputs.removeClass('input-' + options.type);
                    }
                }

                $(this).removeData('--label-options');
            }
        });

        return $(this);
    }

    /**
     * create message
     *
     * @param string message
     * @param array options (type [success], append [null, extra text], inputs [null, inputs])
     * @returns object
     */
    $.fn.createMessage = function(message, options) {
        $.each(this, function() {
            if (!options) options = {type: 'success', append: null, inputs: null};
            // ----------------------------------------------
            if (!options.type) options.type = 'success';
            if (!options.append) options.append = null;
            if (!options.inputs) options.inputs = null;

            var $container = $(this).removeMessage();        // remove old message
            $container.data('--message-options', options);

            if (message) {
                options.object = $(['<div class="message alert alert-' + options.type + '">', '<button type="button" class="close">×</button>', message, options.append, '</div>'].join(''));
                $container.prepend(options.object);
                options.object.find('button.close').one('click', function() {
                    $container.removeMessage();
                });
            }

            if (options.inputs) {
                if (!(options.inputs instanceof jQuery)) {
                    $.each(options.inputs, function() {
                        $(this).addClass('input-' + options.type);
                    });
                } else {
                    options.inputs.addClass('input-' + options.type);
                }
            }
        });

        return $(this);
    }

    /**
     * remove message
     *
     * @returns object
     */
    $.fn.removeMessage = function() {
        $.each(this, function() {
            if ($(this).data('--message-options')) {
                var options = $(this).data('--message-options');
                if (options.object) options.object.remove();
                if (options.inputs) {
                    if (!(options.inputs instanceof jQuery)) {
                        $.each(options.inputs, function() {
                            $(this).removeClass('input-' + options.type);
                        });
                    } else {
                        options.inputs.removeClass('input-' + options.type);
                    }
                }

                $(this).removeData('--message-options');
            }
        });

        return $(this);
    }

    /**
     * create wait
     *
     * @returns object
     */
    $.fn.createWait = function(options) {
        $.each(this, function() {
            if (!options) options = {size: 30, before: false, toggle: false};
            // ----------------------------------------------
            if (!options.size) options.size = 30;
            if (!options.before) options.before = false;
            if (!options.toggle) options.toggle = false;
            $(this).removeWait();       // remove old wait element

            options.object = $('<span id="waiting-icon" style="width:' + options.size + 'px; display:inline-block"><img src="/images/waiting.gif" alt="" width="' + options.size + 'px" height="' + options.size + 'px"/></span>');
            options.before ? $(this).before(options.object) : $(this).after(options.object);
            if (options.toggle) $(this).hide();

            $(this).data('--wait-options', options);
        });

        return $(this);
    }

    /**
     * remove wait
     *
     * @returns object
     */
    $.fn.removeWait = function() {
        $.each(this, function() {
            if ($(this).data('--wait-options')) {
                var options = $(this).data('--wait-options');
                if (options.object) options.object.remove();
                if (options.toggle) $(this).show();
                $(this).removeData('--message-options');
            }
        });

        return $(this);
    }

    /**
     * ajax bind (magic happens here)
     *
     * @param {type} action
     * @param {type} data
     * @param {type} callback
     * @returns {$}
     */
    $.ajaxCall = function(action, data, callback) {
        if (callback == null) {
            callback = data;
            data = undefined;
        }

        var onResultCallback = function(response) {
            if (response.data == undefined) response.data = {};
            var status = response.status == undefined || response.status;
            if (callback) callback({message: response.message, data: response.data}, status);
        }

        $.ajax(action, {
            type: 'POST',
            data: data,
            dataType: 'json',
            cache: false,
            async: true,

            success: onResultCallback,
            error: function() {
                onResultCallback({message: 'Endpoint was not found.', data: {}, status: false});
            }
        });
    }

    /**
     * ajax bind (magic happens here)
     *
     * @param {type} selector
     * @param {type} data
     * @param {type} callback
     * @returns {$}
     */
    $.fn.ajaxBind = function(selector, data, callback) {
        // process input variables
        if (data == null && callback == null) {
            if (typeof selector === "function") {
                // ( callback )
                callback = selector;
                data = selector = undefined;
            } else {
                // ( data )
                data = selector;
                callback = selector = undefined;
            }
		} else if (callback == null) {
			if (typeof selector === "string") {
				// ( selector, callback )
				callback = data;
				data = undefined;
			} else {
				// ( data, callback )
				callback = data;
                data = selector;
                selector = undefined;
			}
		}

        $.each(this, function() {
            var $rootObject = $(this);
            var $controlObject = null;

            var isCriticalSection = false;
            var leaveCriticalSection = function(evt) {
                if (evt) $(evt.target).add($controlObject).enabled();
                isCriticalSection = false;
                $controlObject = null;
                return false;
            }

            var submitSelector = ':submit,[data-submit],[data-change]';
            // if root object has trigger attribute do not search any deeper
            if ($rootObject.is(submitSelector)) submitSelector = null;
            // find submit trigger elements
            $rootObject.on('click', submitSelector, function(evt) {
                // check if we are in a blocking state
                if (isCriticalSection) return evt.preventDefault();
                $controlObject = $(evt.currentTarget);

                if ($(this).data('submit') == null && $(this).is(':submit')) return true;
                if ($(this).data('change') == null) {
                    evt.preventDefault();
                }

                (selector
                    ? $controlObject.closest(selector)
                    : $controlObject
                ).trigger('submit');
            });

            $rootObject.onif('submit', selector, function(evt) {
                evt.stopImmediatePropagation();     // allow inner form bindings
                evt.preventDefault();

                isCriticalSection = true;        // enter critical section
                if ($(evt.target).data('locked') != undefined) {
                    return leaveCriticalSection(evt);
                }

                $(evt.target).add($controlObject).disabled();
                // =========================================

                var $object = $(this);
                var $waitObjects = $object.findAndSelf('[data-wait]').each(function() {
                    var params = $(this).attr('data-wait').split(',');
                    params = [String(params[0]).toLowerCase() == 'true', Number(params[1]) || 30];

                    $(this).createWait({toggle: params[0], size: params[1]});
                });

                // =========================================
                var callActionHandler = function() {
                    var beforeEvent = $.Event('ajax.bind.before', {target: ($controlObject || $(evt.target)).get(0)});
                    $object.trigger(beforeEvent);

                    if (beforeEvent.isDefaultPrevented()) {
                        return leaveCriticalSection(evt);
                    }

                    // find action attribute
                    var action = $object.attr('action') || $object.data('action') || $object.attr('href');
                    if (!action) {
                        leaveCriticalSection(evt);
                        throw "The 'data-action' attribute was not found.";
                    }

                    // try to find definition for method type
                    var method = $object.attr('method') || $object.data('method') || 'POST';

                    var inputData = {};
                    $object.find(':input[name]:not(button):enabled').each(function() {
                        if ($(this).is(':radio')) {
                            if ($(this).prop('checked')) {
                                inputData[$(this).attr('name')] = $(this).val();
                            }
                        }

                        else if ($(this).is(':checkbox')) {
                            inputData[$(this).attr('name')] = $(this).prop('checked') | 0;
                        }

                        else {
                            inputData[$(this).attr('name')] = $(this).val();
                        }
                    });

                    // gather all params
                    var ajaxData = $.extend({}, inputData, data);
                    // -----------------------------------------
                    var dataEvent = $.Event('ajax.bind.data', {target: ($controlObject || $(evt.target)).get(0)});
                    $object.trigger(dataEvent, ajaxData);
                    if (dataEvent.isDefaultPrevented()) {
                        return leaveCriticalSection(evt);
                    }

                    ajaxData = dataEvent.result || ajaxData;
                    // ------------------------------------

                    var onResultCallback = function(response) {
                        if (response.data == undefined) response.data = {};
                        var status = response.status == undefined || response.status;
                        if (callback) {
                            // if callback returns false stop all other actions immediately
                            if (callback.call($object[0], {message: response.message, data: response.data}, status) === false) {
                                return leaveCriticalSection();      // !! keep control disabled
                            }
                        }

                        $waitObjects.removeWait();
                        // =========================================
                        var afterEvent = $.Event('ajax.bind.after', {target: ($controlObject || $(evt.target)).get(0)});
                        $object.trigger(afterEvent);

                        return leaveCriticalSection(evt);
                    }

                    $.ajax(action, {
                        type: method,
                        data: ajaxData,
                        dataType: 'json',
                        cache: false,
                        async: true,

                        success: onResultCallback,
                        error: function() {
                            onResultCallback({message: 'Endpoint was not found.', data: {}, status: false});
                        }
                    });

                    return false;
                }

                // if ($(evt.target).data('confirm') != null) {
                //     var message = $(evt.target).data('confirm') || 'Do you want to perform this action?';
                //
                //     bootbox.confirm(message, function(result) {
                //         if (!result) {
                //             $waitObjects.removeWait();
                //             leaveCriticalSection(evt);
                //         } else {
                //             callActionHandler();
                //         }
                //     });
                // } else {
                    callActionHandler();        // trigger action handler
                // }
            });
        });

        return this;
    }
});
