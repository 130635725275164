import $ from 'jquery'
import LazyLoad from "vanilla-lazyload";

const controller = () => {

    let lazyload = new LazyLoad({
        container: document.querySelector('div.photos-grid'),
        threshold: 150,
        callback_error (element) {
            // remove images that could not be loaded
            element.closest('div.item').remove()
        }
    })

    let initStatsPieChart = () => {

        if (! window.Chartist) {
            return false
        }

        let $chart = $('div#chart')
        if ($chart.length == 0) {
            return false
        }

        let labels = []
        let series = []

        let total = 0

        $('ul#chart_stats li').each((_, item) => {
            let value = parseInt($(item).data('value'))
            series.push(value)

            total += value
        })

        for (let i = 0; i < series.length; i++) {
            let value = (series[i] / total) * 100
            value > 4
                ? labels.push(`${value.toFixed(1)}%`)
                : labels.push(' ')
        }

        $('div#chart_total').html(`Total Photos<br> ${total.toString()}`)

        new Chartist.Pie($chart[0], {
            series,
            labels
        }, {
            donut: true,
            donutWidth: 50,
            donutSolid: true,
            showLabel: true
        });
    }


    let initInfinityScroll = () => {
        let $container = $('div.photos-grid')
        // ------------------------------
        let resourceUri = $container.data('fetch-action')

        if (! resourceUri) {
            return;
        }

        const triggerScrollEvent = () => {
            let rect = $container[0].getBoundingClientRect();

            if ($(window).height() >= rect.bottom - 250) {
                loadMoreContent()
            }
        }

        let loadMoreContent = () => {
            let $image = $container.find('div.item:last')
            let params = {
                from_photo_id: $image.data('photo-id')
            }

            $(window).off('scroll.infinite')

            $.ajaxCall(resourceUri, params, (response) => {
                let content = response.data.content || false
                if (! content) {
                    return
                }

                $container.append(content)

                lazyload.update()

                $(window).on('scroll.infinite', triggerScrollEvent)
            })
        }

        $(window).on('scroll.infinite', triggerScrollEvent).trigger('scroll.infinite')
    }

    // -------------------------------------------------------------------------------

    $(function() {
        initStatsPieChart()

        initInfinityScroll()

        $(document).on('click', 'button[name="report"]', evt => {
            $.ajaxCall($(evt.target).data('action'), () => alert('Photo has been reported.'))
        })
    })
}

export default require('system/controller').create(controller)
