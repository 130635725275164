import $ from 'jquery';

import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-bs/css/dataTables.bootstrap.css';

let initialize = function (object, options) {

    let originalInitComplete = options.initComplete || null;
    // override initComplete method
    options.initComplete = function (settings, json) {
        let api = this.api();

        if (options.buttonsContainer) {
            let $container = $(settings.nTableWrapper).find('div.row:first div.buttons');
            if ($container.length > 0) {
                $(options.buttonsContainer).contents().appendTo($container);
            }
        }

        let $filters = $(this).find('tr.filter');
        if ($filters.length > 0) {

            let searchTimerHandle = null;

            // Apply the search
            api.columns().every(index => {
                let $filterInput = $filters.find('td:eq('+ index +') :input');
                if ($filterInput.length > 0) {
                    let column = api.columns(index);
                    let event = $filterInput.is('select')
                        ? 'change'
                        : 'keyup'

                    $filterInput.val(column.search()[0]);
                    $filterInput.on(event, (evt) => {
                        clearTimeout(searchTimerHandle);    // reset timeout

                        if (column.search() !== evt.target.value) {
                            searchTimerHandle = setTimeout(() => {
                                column.search(evt.target.value).draw();
                            }, 400);
                        }
                    });
                }
            });
        }

        if (originalInitComplete) {
            // forward to a user defined init complete method
            originalInitComplete.call(this, settings, json);
        }
    }

    return $(object).DataTable($.extend(true, {
        sDom: "<'row'<'col-sm-4'><'col-sm-8'<'buttons'>>r><'row'<'col-sm-12't>><'row'<'col-sm-6'i><'col-sm-6'p>>",

        stateSave: true,
        stateDuration: 0,

        columnDefs: [
            {searchable: false, targets: ["_all"]},
            {orderable: false, targets: ["_all"]},
        ],

        orderCellsTop: true,
        serverSide: true,
        autoWidth: false,
        pageLength: 25,
        ajax: {
            url: $(object).data('fetch-action'),
            type: 'POST'
        }
    }, options));
}

/**
 * initialize component
 */
export default require('system/component').create('datatables', initialize);
