export function create (controller) {
    return {
        resolve (action) {
            if (typeof controller === "function") {
                controller(action)
            } else if (controller[action]) {
                controller[action](action)
            }
        }
    }
}
