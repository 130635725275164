import $ from 'jquery';
import 'vendor/jquery.nestable';

/**
 * Normalizes the serialized items into a flat array.
 *
 * @param {Array} items
 * @return {Array}
 */
let normalizeItems = function (items) {
    let result = [];
    items.forEach(function (item) {
        result.push(item.itemId);
    });

    return result;
};

/**
 * Normalizes the serialized nestable items into a flat array.
 *
 * @param {Array} items
 * @return {Array}
 */
let normalizeNestableItems = function (items, parent, depth) {

    parent = parent || null;
    depth = depth || 1;

    let result = [];
    items.forEach(function (item) {
        result.push({id: item.itemId, parent: parent, depth: depth});
        if (item.children) {
            result = result.concat(normalizeNestableItems(item.children, item.itemId, depth + 1));
        }
    });

    return result;
};

let initialize = function (object, options) {
    let $container = $(object);
    let isNestableList = options.maxDepth || 1 > 1;

    $container.nestable($.extend(true, {
        maxDepth: 1,
        animateToggle: false,
        listNodeName: 'ol',
        itemNodeName: 'li',
        rootClass: 'itemList-panel',
        listClass: 'itemList-list',
        itemHeaderClass: 'itemList-item-header',
        itemClass: 'itemList-item',
        dragClass: 'itemList-item--dragged',
        handleClass: 'itemList-item-contents',
        collapsedClass: 'itemList-item--collapsed',
        placeClass: 'itemList-placeholder',
        noDragClass: 'itemList-noDrag',
        emptyClass: 'itemList-empty',
        expandBtnHTML: '<button class="itemList-expandCollapseButton" data-action="expand"><i class="fa fa-plus"></i></button>',
        collapseBtnHTML: '<button class="itemList-expandCollapseButton" data-action="collapse"><i class="fa fa-minus"></i></button>'
    }, options));

    if (isNestableList) {
        /**
         * recalculate nestable columns
         */
        let recalculateNestableColumns = function () {
            // reset width calculations for the first column
            $container.find('.itemList-item th:first-child').css('width', '');

            let $nestedLists = $container.find('.itemList-list .itemList-list');
            if ($nestedLists.length > 0) {
                let observableWidth = $container.find('.itemList-item:first th:first').outerWidth();

                $nestedLists.each(function () {
                    let offset = $(this).css('padding-left').replace('px', '');
                    let level = $(this).parentsUntil($container, '.itemList-list').length;

                    $(this).find('.itemList-item th:first-child').css('width', observableWidth - offset * level);
                });
            }
        }

        $(window).on('resize', recalculateNestableColumns);
        $container.on('change', recalculateNestableColumns);

        /**
         * fix for recalculation (if scrollbar appears)
         */
        let oldHasVScroll = null;
        setInterval(function () {
            let hasVScroll = window.innerWidth > document.documentElement.clientWidth;

            if (hasVScroll != oldHasVScroll) {
                recalculateNestableColumns();
                oldHasVScroll = hasVScroll;
            }
        }, 100);
    }

    $container.find('[data-button="sortable.confirm"]').ajaxBind().on('ajax.bind.data', function () {
        let items = $container.nestable('serialize');
        return {items: isNestableList ? normalizeNestableItems(items) : normalizeItems(items)};
    });
}

/**
 * initialize component
 */
export default require('system/component').create('sortable', initialize);
