import $ from 'jquery'

export function resolve () {
    $('[data-component]').each(function () {
        let component = $(this).data('component')

        if (! $(this).data('resolved')) {
            require(`components/${component}.js`).default.create()
        }
    })
}
