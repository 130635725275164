import $ from 'jquery'
import swal from './ui/swal'

const ui = {
    swal,

    form: {
        message (message, options = {}) {
            options.container = options.container || null
            options.type = options.type || 'success'

            $('[data-message]', options.container).html(
                `<div class="alert alert-${options.type}">${message}</div>`
            )
        },

        messageClear (options = {}) {
            options.container = options.container || null

            $('[data-message]', options.container).empty()
        },

        validation (items, options = {}) {
            options.container = options.container || null

            ui.form.validationClear(options)

            for (let key in items) {
                let item = Array.isArray(items[key]) ? items[key][0] : items[key]

                let $object = $(`[data-validation-alert="${key}"]`, options.container).html(
                    `<div class="alert alert-danger">${item}</div>`
                )
            }
        },

        validationClear (options = {}) {
            options.container = options.container || null

            $('[data-validation-alert]', options.container).empty()
        }
    },

    table: {
        toggleDetails (action, options = {}) {
            options.class = options.class || ['fa-plus-square-o', 'fa-minus-square-o']

            return $('<i class="fa ' + options.class[0] + '"></i>').css('cursor', 'pointer').on('click', function () {
                let $row = $(this).closest('tr')
                let $next = $row.next()

                if ($(this).hasClass(options.class[0])) {
                    $(this).removeClass(options.class[0]).addClass(options.class[1])
                    if (!$row.data('details')) {
                        $.ajaxCall(action, function (response) {
                            let $details = $([
                                '<tr class="details" style="display:none">',
                                '<td colspan="' + $('td', $row).length + '">' + (response.data.content || '') + '</td>',
                                '</tr>'
                            ].join(''))

                            $row.data('details', $details)
                            $row.after($details)

                            if (options instanceof Function) {
                                options($details)
                            } else if (options.callback) {
                                options.callback($details)
                            }

                            $details.show()
                        })
                    } else {
                        $row.after($row.data('details'))
                    }
                } else {
                    $(this).removeClass(options.class[1]).addClass(options.class[0])
                    if ($next.is('tr.details')) {
                        $row.data('details', $next.detach())
                    }
                }
            })
        }
    },

    button (action, options = {}) {

        let element = options.element || 'button'

        let elementBuilder = []

        elementBuilder.push(
            element != 'a'
                ? `data-action="${action}"`
                : `href="${action}"`
        )

        if (options.confirm) {
            elementBuilder.push(
                typeof options.confirm === 'string'
                    ? `data-confirm="${options.confirm}"`
                    : 'data-confirm'
            )
        }

        if (typeof options.state !== undefined) {
            let state = Number(options.state)
            if (options.stateGroups && options.stateGroups[state]) {
                options.title = (options.title || '') + (options.stateGroups[state].title || '');
                options.class = options.class
                    ? options.class + ' ' + (options.stateGroups[state].class || '')
                    : options.stateGroups[state].class || ''

                options.icon = options.icon
                    ? options.icon + ' ' + (options.stateGroups[state].icon || '')
                    : options.stateGroups[state].icon || ''
            }
        }

        if (options.class) {
            elementBuilder.push(`class="${options.class}"`)
        }

        elementBuilder.push('data-submit')

        return $(`
            <${element} ${elementBuilder.join(' ')}>
                ${options.icon ? `<i class="${options.icon}"></i>` : ''} <span class="hidden-sm hidden-xs">${options.title || ''}</span>
            </${element}>
        `)
    },

    buttonHref (action, options = {}) {
        options.element = 'a'

        return ui.button(action, options)
    }
}

export default ui
