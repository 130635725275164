import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'

export default {
    confirm (text = 'Do you want to perform this action?', icon = 'question') {
        return Swal.fire({
            icon,
            text,
            showCancelButton: true,
            confirmButtonColor: '#d33'
        })
    },

    alert (text, icon = 'success') {
        if (typeof text === 'object') {
            return Swal.fire(text)
        }

        return Swal.fire({ icon, text })
    },

    input (title) {
        return Swal.fire({
            title,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            }
        })
    }
}
