import $ from 'jquery';

let initialize = function ($body, options) {
    if (options.setup) {
        /**
         * load system helpers
         */
        require('system/helpers')

        options.setup()
    }

    let module = $body.data('module')
    if (module) {
        let action = null
        let index = module.lastIndexOf('.')
        if (index > 0) {
            action = module.substr(index + 1)
            module = module.substr(0, index)
        }

        action = action || 'index'
        module = module.replace(/\./g, '/')

        if (options.modules) {
            try {
                options.modules(module, action)
            } catch (e) {
                console.warn(`[debug] Unable to resolve module "./${module}.js"`)
                console.error(e)
            }
        }
    }

    require('system/component.resolver').resolve()
}

export function load (options) {
    let $body = $(document).find('body')
    if ($body.length == 0) {
        $(document).on('ready', function () {
            $body = $(document).find('body')
            if ($body.length == 0) {
                throw `It looks like the <body> tag is missing.`
            }

            initialize($body, options)
        })
    } else {
        initialize($body, options)
    }
}
