import $script from 'scriptjs';

let initialize = function (object, options) {
    options.package = options.package || 'standard-all';
    options.version = options.version || '4.9.2';

    $script(`//cdn.ckeditor.com/${options.version}/${options.package}/ckeditor.js`, function () {
        if (options.beforeInit) {
            options.beforeInit();
        }

        if (options.inline) {
            CKEDITOR.inline(object, $.extend(true, {}, options));
        } else {
            CKEDITOR.replace(object, $.extend(true, {
                height: 300,

                htmlEncodeOutput: false,
                entities: false
            }, options));
        }
    });
}

/**
 * initialize component
 */
export default require('system/component').create('ckeditor', initialize);
