export function create (component, callback) {
    return {
        create (selector, options) {
            let isJQueryObject = selector instanceof jQuery
            let isDomObject = selector instanceof HTMLElement || selector instanceof HTMLCollection

            if (typeof selector === "object" && !isJQueryObject && !isDomObject) {
                options = selector
                selector = undefined
            }

            let results = []

            let $components = (typeof selector === "undefined")
                ? $(`[data-component="${component}"]`)
                : $(selector)

            $components.each(function () {
                if ($(this).data('resolved')) {
                    return; // component is resolved already, skip...
                }

                // mark component as resolved already
                $(this).data('resolved', true)

                let result = callback(this, options || {})
                if (result) {
                    results.push(result)
                }
            })

            if (results.length == 0) {
                return null
            }

            return results.length == 1
                ? results[0]
                : results
        }
    }
}
