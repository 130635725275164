import $ from 'jquery';
import 'select2/dist/js/select2.full.js';

import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';

let initialize = function (object, options) {
    let $component = $(object).select2($.extend(true, {
        theme: 'bootstrap',
        width: '100%',
        language: $('html').attr('lang') || 'en',
        placeholder: '',
        allowClear: true
    }, options));

    /**
     * fix: prevent select2:open when clearing selection
     */
    $component.on('select2:unselect', () => {
        $component.data('select2').options.set('disabled', true);

        setTimeout(() => {
            $component.data('select2').options.set('disabled', false);
        }, 0);
    });

    return $component;
}

/**
 * initialize component
 */
export default require('system/component').create('select2', initialize);
